import React from 'react'

class HubspotForm extends React.Component {
  componentDidMount() {
    const script = document.createElement('script')
    script.src = 'https://js.hsforms.net/forms/shell.js'
    document.body.appendChild(script)

    script.addEventListener('load', () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          region: 'na1',
          portalId: '5823156',
          formId: '31243929-4b53-4b8e-b57c-b64d64e3d185',
          target: '#hubspotForm',
        })
      }
    })
  }

  render() {
    return <div id="hubspotForm" style={{ minHeight: '450px' }}></div>
  }
}

export default HubspotForm

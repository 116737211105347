import React from 'react'
import Layout from '../components/layout'
import Seo from '~/components/seo'
import HubspotForm from '~/components/form/hubspot'
import * as s from '../styles/single.module.scss'
import PageHeadline from '../components/pageHeadline'

const ContactPage = () => {
  return (
    <Layout>
      <Seo title="contact" pageUrl="contact" />
      <div className={s.staticArticle}>
        <header className={s.articleHeader}>
          <PageHeadline text="Contact" />
        </header>
        <div className={s.articleBody}>
          <HubspotForm />
        </div>
      </div>
    </Layout>
  )
}

export default ContactPage
